import React, { useEffect, useState } from 'react'
// Ant Design
import { Breadcrumb, Flex } from 'antd'
// Layout
import { Navbar } from '../layout/Navbar'
// React Router
import { useNavigate } from 'react-router-dom'
// Fragments
import { Buscador } from '../fragments/Campañas/Buscador'
import { Filtro } from '../fragments/Campañas/Filtro'
import { Tabla } from '../fragments/Campañas/Tabla'
import { Botones } from '../fragments/Campañas/Botones'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import {API_URL} from '../../config'

export const Campañas = () => {

    // Para el idioma
    const { i18n } = useTranslation(["translation"]);
   
    // Variables de estado
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const [name, setName] = useState(null)
    const [dateInicio, setDateInicio] = useState(null)
    const [dateFin, setDateFin] = useState(null)
    const [cargando, setCargando] = useState(false)
    const [mails, setMails] = useState([])
    const navigate = useNavigate()

    const apiMails = async () => {
        try {
            const res = await axios.get(API_URL + "/mails")
            if(res.status === 200) {
                setMails(res.data.mails)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    
    // Consumo api de campañas
    const apiCampañas = async() => {
        setCargando(true)
        try {
            const res = await axios.get(API_URL + "/campaigns", {
                withCredentials: true,
                params: {
                    page: page,
                    perPage: pageSize,
                    campaignId: name,
                    startDate: dateInicio,
                    endDate: dateFin,
                }
            })
            if(res.status === 200) {
                setTotal(res.data.total)
                setCargando(false)
                
                apiCampañaMail(res.data.entities)
            }
            else {
                setCargando(false)
            }
        }
        catch (error) {
            setCargando(false)
        }
    }

    // Consumo api campaña mail
    const apiCampañaMail = async(camp) => {
        setCargando(true)
        try {
            const res = await axios.get(API_URL + "/mails/campaign")
            if(res.status === 200) {
                const arr = [...camp, ...res.data]
                setData(arr)
                setCargando(false)
            }
        }
        catch (error) {
            console.log(error)
            setCargando(false)
        }
    }

    const isLogin = async() => {
        try {
            await axios.get(API_URL + "/auth/checkLogin",{
                withCredentials: true
            
            })
        }
        catch (error) {
            if(error.response.status === 401) {
                navigate("/login")
            }
        }
    }

    useEffect(() => {
        apiCampañas()
        isLogin()
        apiMails()
    }, [page, pageSize, name, dateInicio, dateFin, i18n.language])


    return (
        <section>
            <Navbar />

            <div style={{padding: "10px"}}>
                <Breadcrumb 
                    style={{fontSize: "18px"}}
                    items={[
                        {
                            title: "Home /",
                        },
                    ]}
                />
            </div>

            <Flex justify="space-between" style={{padding: "0 10px"}}>
                <Filtro setDateInicio={setDateInicio} setDateFin={setDateFin} />
                <Buscador setName={setName} />
            </Flex>

            <Tabla dataApi={data} total={total} page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} cargando={cargando} apiCampañas={apiCampañas} mails={mails} />
            <Botones />
        </section>
    )
}
