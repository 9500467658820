// Estilos de index.html
export const VenomConfig = {
    titulo: window.murphyVenom.titulo,
    logo: window.murphyVenom.logo,
    fondo: window.murphyVenom.fondo,
    colorPrimario: window.murphyVenom.colorPrimario,
    colorSecundario: window.murphyVenom.colorSecundario,
    degradado: window.murphyVenom.degradado,
}


// Funciones para mandar los valores
export const getCongif = (nombreVariable) => {
    return VenomConfig[nombreVariable];
}

// Url de la api
export const API_URL = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
// export const API_URL = "http://localhost:8000";
