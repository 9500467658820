import React from 'react'
// Ant Design
import { Breadcrumb, Flex } from 'antd'
// React Router
import { Link } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'

export const BreadCrumb = () => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    return (
        <Flex style={{width: "50%"}}>
            <Breadcrumb 
                style={{fontSize: "18px"}}
                items={[
                    {
                        title: <Link to="/campañas">Home</Link>,
                    },
                    {
                        title: t("list_emails"),
                    },
                ]}
            />
        </Flex>
    )
}
