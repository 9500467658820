import React, { useEffect, useState } from 'react'
// React Router
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Flex, Typography, Input, Select, Upload, Button, Progress, message } from 'antd'
// Icons
import { InboxOutlined, CheckCircleOutlined } from '@ant-design/icons'
// i18
import { useTranslation } from 'react-i18next'
// Configuraciones
import { getCongif } from '../../../config'
// Axios
import axios from 'axios';
// Config
import {API_URL} from '../../../config'

const { Title } = Typography;
const { Dragger } = Upload;

export const Form = () => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    // Consumo de api de senders
    const [senders, setSenders] = useState([])
    const apiSenders = async() => {
        try {
            const res = await axios.get(API_URL + "/campaigns/senders",{
                withCredentials: true
            })
            if(res.status === 200) setSenders(res.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        apiSenders()
    }, [])

    // Para subir archivos
    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        onRemove: file => {
            setFileList([]);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    // Para el mensaje de error
    const [messageApi, contextHolder] = message.useMessage();
    const Mensaje = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
            duration: 6,
        });
    };

    // Enviar datos
    const [nombre, setNombre] = useState("");
    const [numero, setNumero] = useState("");
    const [fileList, setFileList] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [vista, setVista] = useState(false);

    const onArchivos = async() => {
        if(nombre==="" || numero==="" || fileList.length===0) {
            Mensaje(t("message_error_add"));
        }
        else {
            setCargando(true);

            let url;
            if(tipo===1) {
                url = API_URL + `/campaigns/upload/${nombre}/${numero}`
            }
            else {
                url = API_URL + `/mails/upload/${nombre}/${numero}`
            }

            try {
                const res = await axios.post(url, fileList, {
                    // withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if(res.status===200) {
                    setCargando(false);
                    setVista(true);
                    apiCargando();
                }
            }
            catch (error) {
                setCargando(false);
                if(error.response.data.errorCode==="INVALID_HEADERS") {
                    Mensaje(t("message_error_INVALID_HEADERS"))
                }
                else if(error.response.data.errorCode==="INVALID_DATETIME_FORMAT") {
                    Mensaje(t("message_error_INVALID_DATETIME_FORMAT"))
                }
                else if(error.response.data.errorCode==="MISSING_DATA") {
                    Mensaje(t("message_error_MISSING_DATA"))
                }
                else if(error.response.data.errorCode==="EMPTY_FILE") {
                    Mensaje(t("message_error_EMPTY_FILE"))
                }
                else if(error.response.data.errorCode==="FILE_EXISTS") {
                    Mensaje(t("message_error_FILE_EXISTS"))
                }
                else if(error.response.data.errorCode==="INVALID_PHONE") {
                    Mensaje(t("message_error_INVALID_PHONE"))
                }
                else if(error.response.data.errorCode==="INVALID_MAIL") {
                    Mensaje(t("message_error_INVALID_EMAIL"))
                }
            }
        }
    }

    // Para api barra de cargando
    const [progreso, setProgreso] = useState(0);
    const navigate = useNavigate();
    
    const apiCargando = async() => {
        let num = 0;
        while(num<100) {
            try {
                const res = await axios.get(API_URL + `/campaigns/status/upload/${nombre}`)
                if(res.status===200) {
                    setProgreso((res.data).toFixed(0));
                    num = res.data
                    if(res.data===100) {
                        setTimeout(() => {
                            navigate('/campañas');
                        }, 4000);
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }
    }

    // Seleccionar tipo de campaña
    const [tipo, setTipo] = useState(1);
    const [sendersMail, setSendersMail] = useState([])

    const apiSenderMail = async() => {
        try {
            const res = await axios.get(API_URL + "/mails/senders")
            if(res.status === 200) setSendersMail(res.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        apiSenderMail()
    }, [])


    return (
        <>
            {contextHolder}
            <Flex vertical align="center" style={{width: "100%"}}>

                <Title level={4}>{t("type_campaign")}</Title>
                <Select
                    style={{ width: "60%" }}
                    size="large"
                    options={[
                        {label: "WhatsApp", value: 1},
                        {label: t("mail"), value: 2}
                    ]}
                    onChange={(e) => setTipo(e)}
                    value={tipo}
                    disabled={vista}
                />

                <Title level={4} style={{marginTop: "20px"}}>{t("input_title_campaign")}</Title>
                <Input 
                    onChange={(e) => setNombre(e.target.value.trim())}
                    placeholder={t("input_placeholder_name")} 
                    size="large" 
                    style={{width: "60%"}} 
                    disabled={vista}
                />

                <Title level={4} style={{marginTop: "20px"}}>{tipo===1 ? t("input_number_send") : t("input_mail_send")}</Title>
                <Select
                    placeholder={t("input_placeholder_number")}
                    style={{ width: "60%" }}
                    size="large"
                    allowClear
                    options={tipo===1 
                        ? senders.map(sender => ({label: sender.phoneSender, value: sender.phoneSender}))
                        : sendersMail.map(sender => ({label: sender.emailSender, value: sender.emailSender}))
                    }
                    onChange={(e) => setNumero(e)}
                    disabled={vista}
                />

                <Title level={4} style={{marginTop: "20px"}}>{t("input_title_file_csv")}</Title>
                {vista
                    ? <Progress type="circle" percent={progreso} />
                    :
                    <Dragger {...props} accept=".csv" maxCount={1}>
                        <p className="ant-upload-drag-icon">
                            {fileList.length > 0 
                                ? <CheckCircleOutlined style={{color: "#2CA438"}} />
                                : <InboxOutlined style={{color: getCongif("colorPrimario")}} />
                            }
                        </p>
                        <p className="ant-upload-text">{t("header_title_add")}</p>
                        <p className="ant-upload-hint">{t("text_primary_add")}</p>
                        {tipo===1 
                            ? <p className="ant-upload-hint">{t("text_secondary_add")}</p>
                            : <p className="ant-upload-hint">{t("text_secondary_add_dos")}</p>
                        }
                        {tipo===1 && <p className="ant-upload-hint">{t("text_third_add")}</p>}
                    </Dragger>
                }

                {!vista && <Button onClick={onArchivos} type="ghost" loading={cargando} style={{margin: "20px 0", fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{t("btn_upload_add")}</Button>}
            </Flex>
        </>
    )
}
