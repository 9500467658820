import React, { useEffect, useState } from 'react'
// React Router 
import { useNavigate } from 'react-router-dom'
// Ant Design
import { Button, Flex, Table, Tooltip, Popconfirm, Modal, message } from 'antd'
// Ant Design Icons
import { DeleteOutlined, EyeOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
// i18
import { useTranslation } from 'react-i18next'
// Moment js
import moment from 'moment'
// Configuraciones
import { API_URL, getCongif } from '../../../config'
// Axios
import axios from 'axios'

export const Tabla = ({dataApi, cargando, total, apiMails}) => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);
    // Para navegar
    const navigate = useNavigate();

    const columns = [
        {
          title: t("name"),
          dataIndex: 'nombre',
        },
        {
          title: t("subject"),
          dataIndex: 'asunto',
        },
        {
          title: t("table_created"),
          dataIndex: 'created_at',
          width: 180,
        },
        {
          title: '',
          dataIndex: 'visualizar',
          width: 60,
          align: 'center',
        },
        {
          title: '',
          dataIndex: 'editar',
          width: 60,
          align: 'center',
        },
        {
          title: '',
          dataIndex: 'eliminar',
          width: 60,
          align: 'center',
        },
    ];

    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
            duration: 6,
        });
    };

    // Formatear fecha
    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
    };

    // Eliminar email
    const [cargandoDelete, setCargandoDelete] = useState(false)

    const onDelete = async (id) => {
        setCargandoDelete(true)
        try {
            const res = await axios.delete(API_URL + `/mails/delete/${id}`)
            if(res.status === 200) {
                apiMails()
                setCargandoDelete(false)
            }
        }
        catch (error) {
            setCargandoDelete(false)
            messageError(t("error_delete"))
        }
    }

    // Al dar click en editar
    const onEdit = async (item) => {
        const {name, subject, dataJson, dataCss} = item;
        localStorage.setItem("nameTemplate", name);
        localStorage.setItem("subjectTemplate", subject);
        localStorage.setItem("dataJson", JSON.stringify(dataJson));
        localStorage.setItem("dataCss", dataCss);

        navigate("/mails/crear");
    }
    
    // Para data de tabla
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        dataApi?.map((item, index) => (
            arr.push({
                key: index,
                nombre: item.name,
                asunto: item.subject,
                created_at: formatDate(item.createdAt),
                visualizar: (
                    <Tooltip title={t("view")} color="#4472C5">
                        <Button onClick={() => showModal(item.contentHTML, item.name, item.dataCss)} type="ghost" shape="circle" style={{color: "#4472C5", border: "1px solid #4472C5"}} icon={<EyeOutlined />} />
                    </Tooltip>
                ),
                editar: (
                    <Tooltip title={t("edit")} color="#FFC107">
                        <Button onClick={() => onEdit(item)} type="ghost" shape="circle" style={{color: "#FFC107", border: "1px solid #FFC107"}} icon={<EditOutlined />} />
                    </Tooltip>
                ),
                eliminar: (
                    <Tooltip title={t("btn_delete")} color="#FF0000">
                        <Popconfirm
                            title={t("alert_title_file")}
                            onConfirm={() => onDelete(item.name)}
                            description={t("alert_description_file")}
                            cancelText="No"
                            zIndex={999999}
                        >
                            <Button type="ghost" shape="circle" loading={cargandoDelete} style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                ),
            })
        ))
        setData(arr);
    }, [dataApi])


    // Modal de preview
    function toCamelCase(str) {
        return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    }

    // Para sacar los valores del css
    function extractCSS(cssString, className) {
        const regex = new RegExp(`\\.${className}\\s*{([^}]*)}`, 'g');
        const match = regex.exec(cssString);
        if (!match) return {};
    
        const properties = match[1].split(';').filter(Boolean);
        const styleObject = {};
    
        properties.forEach(property => {
            const [key, value] = property.split(':').map(item => item.trim());
            if (key && value) {
                styleObject[toCamelCase(key)] = value;
            }
        });
    
        return styleObject;
    }

    const [visible, setVisible] = useState(false)
    const [html, setHtml] = useState("")
    const [name, setName] = useState("")
    const [color, setColor] = useState({})
    const showModal = (html, name, css) => {
        setHtml(html)
        setName(name)
        
        const colorCss = extractCSS(css, "body")
        const cssStyles = {
            height: "60vh", 
            overflow: "auto",
        }
        const combinedStyles = { ...cssStyles, ...colorCss };
        setColor(combinedStyles)
        
        setVisible(true)
    }

    return (
        <div style={{padding: "0 80px"}}>
            <Flex justify="flex-end">
                <Button onClick={() => navigate("/mails/crear")} type="ghost" icon={<PlusOutlined />} style={{marginTop: "5px", fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{t("create")}</Button>
            </Flex>

            <Flex style={{width: "100%", marginTop: "10px"}}>
                <Table
                     pagination={{
                        pageSize: 20,
                        total: total,
                        position: ["bottomCenter"],
                        showSizeChanger: false,
                    }}
                    scroll={{
                        y: '80vh',
                    }}
                    loading={cargando}
                    style={{width: "100%"}}
                    columns={columns}
                    dataSource={data}
                />
            </Flex>

            <Modal
                title={t("preview") + name}
                centered
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className="skin__scrollbar" style={color} dangerouslySetInnerHTML={{ __html: html }} />
            </Modal>


            {contextHolder}
        </div>
    )
}
