import React from 'react'
// Ant Design
import { Flex, Input } from 'antd'
const { Search } = Input;

export const Buscador = ({t}) => {
    return (
        <Flex style={{width: "50%", zIndex: "10"}} justify="flex-end">
            <Search placeholder={t("name")} allowClear size="large" style={{ width: "60%" }} />
        </Flex>
    )
}
