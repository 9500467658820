import React, { useState } from 'react'
// Ant Design
import { Flex, Button, message } from 'antd'
// React Router
import { useNavigate } from 'react-router-dom'
// Axios
import axios from 'axios'
// i18
import { useTranslation } from 'react-i18next'
// Config
import { API_URL } from '../../../config'

export const VistaPrevia = ({visual, name, asunto, cssValue}) => {
    const navigate = useNavigate()

    // Para el idioma
    const { t } = useTranslation(["translation"])

    // Para el mensaje de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
            duration: 6,
        });
    };

    // Función para convertir kebab-case a camelCase
    function toCamelCase(str) {
        return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    }

    // Para sacar los valores del css
    function extractCSS(cssString, className) {
        const regex = new RegExp(`\\.${className}\\s*{([^}]*)}`, 'g');
        const match = regex.exec(cssString);
        if (!match) return {};
    
        const properties = match[1].split(';').filter(Boolean);
        const styleObject = {};
    
        properties.forEach(property => {
            let [key, value] = property.split(':').map(item => item.trim());
            if (key && value) {
                value = value.replace(/!important\s*$/, '');
                styleObject[toCamelCase(key)] = value;
            }
        });
    
        return styleObject;
    }
    
    // Para la preview del email
    function Imagen(key, url, ancho, largo, posicion, tipo) {

        const cssPlus = extractCSS(cssValue, tipo+key)
        const cssStyles = {
            width: ancho, 
            height: largo, 
            margin: posicion==="center" ? "auto" : 0, 
            float: posicion==="right" ? "right" : "none", 
            marginBottom: "10px",
            overflow: "hidden",
        }
        const combinedStyles = { ...cssStyles, ...cssPlus };

        return (
            <div key={key} style={combinedStyles}>
                <img src={url} alt={'Imagen '+ key} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
            </div>
        )
    }

    function Texto(key, cuerpo, color, posicion, font, tipo) {
        
        const cssPlus = extractCSS(cssValue, tipo+key)
        const cssStyles = {
            fontSize: font, 
            fontFamily: "Arial", 
            textAlign: posicion, 
            color: color,
        }
        const combinedStyles = { ...cssStyles, ...cssPlus };

        return (
            <div key={key} style={{width: '100%', height: 'auto', marginBottom: '10px'}}>
                <p style={combinedStyles}>{cuerpo}</p>
            </div>
        )
    }

    function Boton(key, texto, url, color, colorTexto, posicion, tipo) {
        
        const cssPlus = extractCSS(cssValue, tipo+key)
        const cssStyles = {
            color: colorTexto, 
            backgroundColor: color, 
            fontFamily: 'Arial', 
            textDecoration: 'none', 
            padding: '10px 15px', 
            borderRadius: '10px',
        }
        const combinedStyles = { ...cssStyles, ...cssPlus };

        return (
            <div key={key} style={{width: '100%', height: 'auto', margin: '20px 0', textAlign: posicion}}>
                <a href={url} style={combinedStyles}>{texto}</a>
            </div>
        )
    }

    function Link(key, texto, url, posicion, tipo) {
                
        const cssPlus = extractCSS(cssValue, tipo+key)
        const cssStyles = {
            fontFamily: 'Arial', 
        }
        const combinedStyles = { ...cssStyles, ...cssPlus };

        return (
            <div key={key} style={{width: '100%', height: 'auto', marginBottom: '10px', textAlign: posicion}}>
                <a href={url} style={combinedStyles}>{texto}</a>
            </div>
        )
    }

    // Para el mail de sendgrid
    function ImagenString(key, url, ancho, largo, posicion, tipo) {
        return `<div key='${key}' style='width: 100%; height: auto; margin-bottom: 10px; text-align: ${posicion}; overflow: hidden;'><img class='${tipo+key}' src='${url}' style='width: ${ancho}; height: ${largo}; object-fit: cover;' /></div>`;
    }

    function TextoString(key, cuerpo, color, posicion, font, tipo) {
        return `<div key='${key}' style='width: 100%; height: auto; margin-bottom: 10px;'><p class='${tipo+key}' style='font-size: ${font}; font-family: Arial; text-align: ${posicion}; color: ${color};'>${cuerpo}</p></div>`;
    }
    
    function BotonString(key, texto, url, color, colorTexto, posicion, tipo) {
        return `<div key='${key}' style='width: 100%; height: auto; margin: 20px 0; text-align: ${posicion};'><a class='${tipo+key}' href='${url}' style='color: ${colorTexto}; background-color: ${color}; font-family: Arial; text-decoration: none; padding: 10px 15px; border-radius: 10px;'>${texto}</a></div>`;
    }
    
    function LinkString(key, texto, url, posicion, tipo) {
        return `<div key='${key}' style='width: 100%; height: auto; margin-bottom: 10px; text-align: ${posicion};'><a class='${tipo+key}' href='${url}' style='font-family: Arial;'>${texto}</a></div>`;
    }

    const htmlContent = `<html><head><title>UVP</title><style type='text/css'>body {font-family: Arial, sans-serif;} ${cssValue}</style></head><body class='body'><div data-role='module-unsubscribe' class='module' role='module' data-type='unsubscribe' style='color:#444444; padding:16px 16px 16px 16px;' data-muid='4e838cf3-9892-4a6d-94d6-170e474d21e5''>${visual.map((element) => {
        if (element.tipo === "imagen") {
            return ImagenString(element.fila, element.url, element.ancho, element.largo, element.posicion, element.tipo);
        } else if (element.tipo === "texto") {
            return TextoString(element.fila, element.cuerpo, element.color, element.posicion, element.font, element.tipo);
        } else if (element.tipo === "boton") {
            return BotonString(element.fila, element.texto, element.url, element.color, element.colorTexto, element.posicion, element.tipo);
        } else if (element.tipo === "link") {
            return LinkString(element.fila, element.texto, element.url, element.posicion, element.tipo);
        }
    }).join('')}</div></body></html>`;


    // Enviar al back
    const [cargando, setCargando] = useState(false);

    const saveEmail = async () => {
        if(name === "") {
            messageError(t("complete_name"))
        }
        else if(asunto === "") {
            messageError(t("complete_subject"))
        }
        else if(visual.length === 0) {
            messageError(t("add_element_preview"))
        }
        else {
            setCargando(true)

            const emailDetail = {
                name: name,
                subject: asunto,
                contentHTML: htmlContent,
                dataJson: visual,
                dataCss: cssValue
            }
            
            try {
                const res = await axios.post(API_URL + "/mails/create", emailDetail)
                if(res.status === 201) {
                    localStorage.setItem("createdSuccess", "show")
                    setCargando(false)
                    navigate("/mails")
                }
            }
            catch (error) {
                setCargando(false)
                messageError(t("error_mail"))
            }
        }
    }

    const updateEmail = async () => {
        if(name === "") {
            messageError(t("complete_name"))
        }
        else if(asunto === "") {
            messageError(t("complete_subject"))
        }
        else if(visual.length === 0) {
            messageError(t("add_element_preview"))
        }
        else {
            setCargando(true)

            const updateData = {
                name: name,
                subject: asunto,
                contentHTML: htmlContent,
                dataJson: visual,
                dataCss: cssValue
            }
            
            try {
                const res = await axios.put(API_URL + `/mails/update/${name}`, updateData)
                if(res.status === 200) {
                    localStorage.setItem("editSuccess", "show")
                    setCargando(false)
                    navigate("/mails")
                }
            }
            catch (error) {
                setCargando(false)
                messageError(t("error_update"))
            }
        }
    }


    const cssPlus = extractCSS(cssValue, "body")
    const cssStyles = {
        width: "100%", 
        height: "85%", 
        padding: "10px", 
        border: "2px solid #D9D9D9", 
        overflow: "auto",
    }
    const combinedStyles = { ...cssStyles, ...cssPlus };
    
    return (
        <Flex vertical style={{position: "relative", width: "40%", height: "98vh", padding: "0 10px"}}>
            <div className="skin__scrollbar" style={combinedStyles}>
                {visual.map((element) => {
                    if (element.tipo === "imagen") {
                        return Imagen(element.fila, element.url, element.ancho, element.largo, element.posicion, element.tipo)
                    } 
                    else if (element.tipo === "texto") {
                        return Texto(element.fila, element.cuerpo, element.color, element.posicion, element.font, element.tipo)
                    } 
                    else if (element.tipo === "boton") {
                        return Boton(element.fila, element.texto, element.url, element.color, element.colorTexto, element.posicion, element.tipo)
                    } 
                    else if (element.tipo === "link") {
                        return Link(element.fila, element.texto, element.url, element.posicion, element.tipo)
                    }
                })}
            </div>

            <Flex align="center" justify="center" style={{width: "100%", height: "15%"}}>
                <Button onClick={localStorage.getItem("dataJson")===null ? saveEmail : updateEmail} type="ghost" size="large" loading={cargando} style={{fontWeight: "600", color: "#01881C", border: "1.8px solid #01881C"}}>{localStorage.getItem("dataJson")===null ? t("btn_create") : t("update")}</Button>
                <Button onClick={() => navigate("/mails")} type="ghost" size="large" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_cancel")}</Button>
            </Flex>

            {contextHolder}
        </Flex>
    )
}
