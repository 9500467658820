import React, { useEffect, useState } from 'react'
// Layout
import { Navbar } from '../layout/Navbar'
import { BtnRegresar } from '../layout/BtnRegresar'
// Fragments
import { BreadCrumb } from '../fragments/Mails/BreadCrumb'
import { Buscador } from '../fragments/Mails/Buscador'
import { Tabla } from '../fragments/Mails/Tabla'
// Ant Design
import { Flex, message } from 'antd'
// i18
import { useTranslation } from 'react-i18next'
// Axios
import axios from 'axios'
// Config
import { API_URL } from '../../config'

export const Mails = () => {
    // Para el idioma
    const { t } = useTranslation();

    // Consumo de api email
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [cargando, setCargando] = useState(false);

    const apiMails = async () => {
        setCargando(true)
        try {
            const res = await axios.get(API_URL + "/mails")
            if(res.status === 200) {
                setData(res.data.mails)
                setTotal(res.data.total)
                setCargando(false)
            }
        }
        catch (error) {
            setCargando(false)
            messageError(t("error_mails"))
        }
    }
    
    useEffect(() => {
        apiMails();

        localStorage.removeItem("nameTemplate");
        localStorage.removeItem("subjectTemplate");
        localStorage.removeItem("dataJson");
        localStorage.removeItem("dataCss");

        const showCreated = localStorage.getItem("createdSuccess");
        const showUpdated = localStorage.getItem("editSuccess");
        if(showCreated === "show") {
            messageSuccess(t("mail_created"))
            localStorage.removeItem("createdSuccess");
        }
        else if(showUpdated === "show") {
            messageSuccess(t("success_update"))
            localStorage.removeItem("editSuccess");
        }
    }, [])

    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
            duration: 6,
        });
    };

    // Para el mensaje de success
    const messageSuccess = (mensaje) => {
        messageApi.open({
            type: 'success',
            content: mensaje,
            duration: 6,
        });
    };

    return (
        <section>
            <Navbar />

            <Flex style={{padding: "10px"}}>
                <BreadCrumb />
                <Buscador t={t} />
            </Flex>

            <Tabla dataApi={data} cargando={cargando} total={total} apiMails={apiMails} />
            <BtnRegresar />

            {contextHolder}
        </section>
    )
}
