import React from 'react'
// Ant Design
import { Breadcrumb } from 'antd'
// React Router
import { Link } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'

export const BreadCrumb = () => {
    // Para el idioma
    const { t } = useTranslation(["translation"]);

    return (
        <Breadcrumb 
            style={{fontSize: "18px", padding: "10px"}}
            items={[
                {
                    title: <Link to="/campañas">Home</Link>,
                },
                {
                    title: <Link to="/mails">{t("list_emails")}</Link>,
                },
                {
                    title: t("create_email"),
                },
            ]}
        />
    )
}
