import React, { useEffect, useState } from 'react'
// Layout
import { Navbar } from '../layout/Navbar'
// Fragments
import { BreadCrumb } from '../fragments/CrearMail/BreadCrumb'
import { Formulario } from '../fragments/CrearMail/Formulario'
import { VistaPrevia } from '../fragments/CrearMail/VistaPrevia'
// Ant Design
import { Flex } from 'antd'

export const CrearMail = () => {
    const [visual, setVisual] = useState([])
    const [name, setName] = useState("")
    const [asunto, setAsunto] = useState("")
    const [cssValue, setCssValue] = useState(`
/* Para aplicar los estilos es importante que agregues !important al final de cada propiedad */
/* To apply the styles it is important to add !important at the end of each property */
.body {}    
`)

    useEffect(() => {
        const dataJson = localStorage.getItem("dataJson");
        const nameTemplate = localStorage.getItem("nameTemplate");
        const subjectTemplate = localStorage.getItem("subjectTemplate");
        const cssValue = localStorage.getItem("dataCss");
        
        if(dataJson!==null) {
            setName(nameTemplate);
            setAsunto(subjectTemplate);
            setVisual(JSON.parse(dataJson));
            setCssValue(cssValue);
        }
    }, [])

    return (
        <section>
            <Navbar />
            <BreadCrumb />

            <Flex>
                <Formulario 
                    visual={visual} 
                    setVisual={setVisual}
                    name={name}
                    setName={setName}
                    asunto={asunto}
                    setAsunto={setAsunto}
                    cssValue={cssValue}
                    setCssValue={setCssValue}
                    />
                <VistaPrevia 
                    visual={visual}
                    name={name}
                    asunto={asunto}
                    cssValue={cssValue}
                />
            </Flex>
        </section>
    )
}
