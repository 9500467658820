import React, { useEffect, useRef, useState } from 'react'
// Ant Design
import { Flex, Typography, Input, Button, Radio, Select, Popconfirm, message, ColorPicker } from 'antd'
// Ant Design Icons
import { FileImageOutlined, EditOutlined, PoweroffOutlined, LinkOutlined, DeleteOutlined, HolderOutlined } from '@ant-design/icons'
// Drag and Drop
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// Configuraciones
import { getCongif } from '../../../config'
// i18
import { useTranslation } from 'react-i18next'
// Ace Editor
import AceEditor from "react-ace"; // Editor de código
import "ace-builds/src-noconflict/mode-css"; // Modo CSS
import "ace-builds/src-noconflict/theme-monokai"; // Tema Monokai
import "ace-builds/src-noconflict/ext-language_tools"; // Herramientas de lenguaje
import "ace-builds/src-noconflict/ext-beautify"; // Beautify

const { Text, Title } = Typography
const { TextArea } = Input

export const Formulario = ({visual, setVisual, name, setName, asunto, setAsunto, cssValue, setCssValue}) => {
    const [view, setView] = useState("1")
    const [arrEdit, setArrEdit] = useState({})
    // Para el idioma
    const { t } = useTranslation(["translation"])

    // Para resetear los elemento (dar click en cancelar)
    const resetElement = () => {
        setView("1")
        setArrEdit({})
    }

    // Para el mensaje de error
    const [messageApi, contextHolder] = message.useMessage();
    const messageError = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
            duration: 6,
        });
    };

    function Content() {
        const iconShow = (icon) => {
            if(icon==="imagen") {
                return <FileImageOutlined style={{fontSize: "30px", color: getCongif("colorPrimario")}} />
            }
            else if(icon==="texto") {
                return <EditOutlined style={{fontSize: "30px", color: getCongif("colorPrimario")}} />
            }
            else if(icon==="boton") {
                return <PoweroffOutlined style={{fontSize: "30px", color: getCongif("colorPrimario")}} />
            }
            else if(icon==="link") {
                return <LinkOutlined style={{fontSize: "30px", color: getCongif("colorPrimario")}} />
            }
        }

        const nameShow = (name, fila) => {
            if(name==="imagen") {
                return "Imagen " + fila
            }
            else if(name==="texto") {
                return "Texto " + fila
            }
            else if(name==="boton") {
                return "Botón " + fila
            }
            else if(name==="link") {
                return "Link " + fila
            }
        }

        const onDelete = (id) => {
            const deleteArr = visual.filter(item => item.fila !== id)
            const reordenado = deleteArr.map((item, index) => ({
                ...item,
                fila: index + 1
            }));
            setVisual(reordenado);
        }

        const onEdit = (id) => {
            const editArr = visual.find(item => item.fila === id)
            setArrEdit(editArr)

            if(editArr.tipo === "imagen") {
                setView("2")
            }
            else if(editArr.tipo === "texto") {
                setView("3")
            }
            else if(editArr.tipo === "boton") {
                setView("4")
            }
            else if(editArr.tipo === "link") {
                setView("5")
            }
        }

        const onDrag = (results) => {
            if (!results.destination) return;
            const indexMove = results.source.index;
            const indexDest = results.destination.index;
        
            const items = Array.from(visual);
            const elementMove = items.find((item) => item.fila === indexMove);
            const elements = items.filter((item) => item.fila !== indexMove);
        
            elements.splice(indexDest - 1, 0, elementMove);
            const newElements = elements.map((item, index) => ({
                ...item,
                fila: index + 1
            }));
            setVisual(newElements)
        }


        let fragmentos = null
        if(visual.length===0) {
            fragmentos = <Text style={{textAlign: "center", fontSize: "16px"}}>{t("no_add_element")}</Text>
        }
        else {
            fragmentos = (
                <DragDropContext onDragEnd={onDrag}>
                    <Droppable droppableId="root" type="group">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={{padding: "0 30px"}}>
                                {visual.map((item) => ( 
                                    <Draggable key={item.fila} draggableId={item.fila.toString()} index={item.fila}>
                                        {(provided) => (
                                            <Flex className="drag__style" key={item.fila} {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} align="center" justify="space-between">
                                                <Flex gap={14}>
                                                    <HolderOutlined style={{fontSize: "18px"}} />
                                                    {iconShow(item.tipo)}
                                                    <Flex vertical>
                                                        <Title level={5} style={{marginBottom: "0"}}>{nameShow(item.tipo, item.fila)}</Title>
                                                        <Text style={{fontSize: "12px"}}>{item.url ? item.url : ""}</Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex gap={15}>
                                                    <Button onClick={() => onEdit(item.fila)} type="ghost" shape="circle" style={{color: "#4472C5", border: "1px solid #4472C5"}} icon={<EditOutlined />} />
                                                    <Popconfirm
                                                        title={t("btn_delete")}
                                                        description={t("answer_delete")}
                                                        onConfirm={() => onDelete(item.fila)}
                                                        okText={t("text_confirm")}
                                                        cancelText="No"
                                                        zIndex={999999}
                                                    >
                                                        <Button type="ghost" shape="circle" style={{color: "#FF0000", border: "1px solid #FF0000"}} icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )
        }
        return <>{fragmentos}</>
    }



    function Imagen() {
        const [url, setUrl] = useState('')
        const [ancho, setAncho] = useState('100%')
        const [largo, setLargo] = useState('300px')
        const [posicion, setPosicion] = useState('center')

        const sendFragment = () => {
            if(url==="") {
                messageError(t("alert_add_url"))
                return
            }

            if(arrEdit.posicion) {
                const editArr = visual.map(item => {
                    if(item.fila === arrEdit.fila) {
                        return {
                            ...item,
                            url: url,
                            ancho: ancho,
                            largo: largo,
                            posicion: posicion
                        }
                    }
                    return item
                })
                setVisual(editArr)
            }
            else {
                setVisual([...visual, {
                    fila: visual.length + 1,
                    tipo: "imagen",
                    url: url,
                    ancho: ancho,
                    largo: largo,
                    posicion: posicion,
                }])
            }
            setView("1")
            setArrEdit({})
        }

        useEffect(() => {
            if(arrEdit.tipo === "imagen") {
                setUrl(arrEdit.url)
                setAncho(arrEdit.ancho)
                setLargo(arrEdit.largo)
                setPosicion(arrEdit.posicion)
            }
        }, [])

        return (
            <Flex vertical>
                <Flex vertical>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("url_image")}</Text>
                    <Input placeholder="http://" size="large" onChange={(e) => setUrl(e.target.value)} value={url} />
                </Flex>

                <Flex style={{marginTop: "15px"}}>
                    <Flex vertical style={{width: "50%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("width_image")}</Text>
                        <Radio.Group size='large' className="custom-radio-group" onChange={(e) => setAncho(e.target.value)} value={ancho}>
                            <Radio.Button value='100%'>{t("large")}</Radio.Button>
                            <Radio.Button value='75%'>{t("medium")}</Radio.Button>
                            <Radio.Button value='50%'>{t("small")}</Radio.Button>
                        </Radio.Group>
                    </Flex>
                    <Flex vertical style={{width: "50%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("height_image")}</Text>
                        <Radio.Group size='large' className="custom-radio-group" onChange={(e) => setLargo(e.target.value)} value={largo}>
                            <Radio.Button value='300px'>{t("large")}</Radio.Button>
                            <Radio.Button value='200px'>{t("medium")}</Radio.Button>
                            <Radio.Button value='100px'>{t("small")}</Radio.Button>
                        </Radio.Group>
                    </Flex>
                </Flex>

                <Flex vertical style={{marginTop: "15px"}}>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("position_image")}</Text>
                    <Select
                        value={posicion}
                        size='large'
                        style={{width: "100%"}}
                        onChange={(e) => setPosicion(e)}
                        options={[
                            {
                                value: 'center',
                                label: t("center"),
                            },
                            {
                                value: 'left',
                                label: t("left"),
                            },
                            {
                                value: 'right',
                                label: t("right"),
                            },
                        ]}
                    />
                </Flex>

                <Flex align="center" justify="center" style={{width: "100%", marginTop: "20px"}}>
                    <Button onClick={sendFragment} type="ghost" style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{arrEdit.posicion ? t("btn_modificate") : t("btn_add")}</Button>
                    <Button onClick={resetElement} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_onback")}</Button>
                </Flex>
            </Flex>
        )
    }



    function Texto() {
        const [cuerpo, setCuerpo] = useState('')
        const [color, setColor] = useState('#000000')
        const [alinear, setAlinear] = useState('left')
        const [font, setFont] = useState('16px')

        const isValidHex = (hex) => {
            const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
            return hexRegex.test(hex);
        };
        
        const sendFragment = () => {
            if(cuerpo==="") {
                messageError(t("alert_text"))
                return
            }
            else if(color==="") {
                messageError(t("alert_color"))
                return
            }
            else if (!isValidHex(color)) {
                message.error(t("alert_hexa"));
                return;
            }

            if(arrEdit.posicion) {
                const editArr = visual.map(item => {
                    if(item.fila === arrEdit.fila) {
                        return {
                            ...item,
                            cuerpo: cuerpo,
                            color: color,
                            posicion: alinear,
                            font: font
                        }
                    }
                    return item
                })
                setVisual(editArr)
            }
            else {
                setVisual([...visual, {
                    fila: visual.length + 1,
                    tipo: "texto",
                    cuerpo: cuerpo,
                    color: color,
                    posicion: alinear,
                    font: font,
                }])
            }
            setView("1")
            setArrEdit({})
        }

        useEffect(() => {
            if(arrEdit.tipo === "texto") {
                setCuerpo(arrEdit.cuerpo)
                setColor(arrEdit.color)
                setAlinear(arrEdit.posicion)
                setFont(arrEdit.font)
            }
        }, [])

        return (
            <Flex vertical>
                <Flex vertical>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("body_mail")}</Text>
                    <TextArea
                        placeholder={t("text_mail")}
                        size="large"
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        onChange={(e) => setCuerpo(e.target.value)}
                        value={cuerpo}
                    />
                </Flex>
                
                <Flex gap={10} style={{marginTop: "15px"}}>
                    <Flex vertical style={{width: "30%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("color_hexa")}</Text>
                        <ColorPicker 
                            style={{width: "100%", display: "flex", justifyContent :"flex-start"}} 
                            size="large" 
                            onChange={(value) => setColor(value.toHexString())} 
                            value={color} 
                            showText
                        />
                    </Flex>
                    <Flex vertical style={{width: "40%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("align_text")}</Text>
                        <Select
                            value={alinear}
                            size="large"
                            style={{width: "100%"}}
                            onChange={(e) => setAlinear(e)}
                            options={[
                                {
                                    value: 'left',
                                    label: t("left"),
                                },
                                {
                                    value: 'center',
                                    label: t("center"),
                                },
                                {
                                    value: 'right',
                                    label: t("right"),
                                },
                            ]}
                        />
                    </Flex>
                    <Flex vertical style={{width: "30%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("size_font")}</Text>
                        <Select
                            value={font}
                            size="large"
                            style={{width: "100%"}}
                            onChange={(e) => setFont(e)}
                            options={[
                                {
                                    value: '10px',
                                    label: '10',
                                },
                                {
                                    value: '12px',
                                    label: '12',
                                },
                                {
                                    value: '14px',
                                    label: '14',
                                },
                                {
                                    value: '16px',
                                    label: '16',
                                },
                                {
                                    value: '18px',
                                    label: '18',
                                },
                                {
                                    value: '20px',
                                    label: '20',
                                },
                                {
                                    value: '22px',
                                    label: '22',
                                },
                                {
                                    value: '24px',
                                    label: '24',
                                },
                                {
                                    value: '26px',
                                    label: '26',
                                },
                                {
                                    value: '28px',
                                    label: '28',
                                },
                                {
                                    value: '30px',
                                    label: '30',
                                },
                            ]}
                        />
                    </Flex>
                </Flex>


                <Flex align="center" justify="center" style={{width: "100%", marginTop: "20px"}}>
                    <Button onClick={sendFragment} type="ghost" style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{arrEdit.posicion ? t("btn_modificate") : t("btn_add")}</Button>
                    <Button onClick={resetElement} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_onback")}</Button>
                </Flex>
            </Flex>
        )
    }



    function Boton() {
        const [texto, setTexto] = useState('')
        const [url, setUrl] = useState('')
        const [color, setColor] = useState(getCongif('colorPrimario'))
        const [colorTexto, setColorTexto] = useState('#FFFFFF')
        const [alinear, setAlinear] = useState('left')

        const isValidHex = (hex) => {
            const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
            return hexRegex.test(hex);
        };

        const sendFragment = () => {
            if(texto==="") {
                messageError(t("alert_text"))
                return
            }
            else if(url==="") {
                messageError(t("alert_url"))
                return
            }
            else if(color==="") {
                messageError(t("alert_boton"))
                return
            }
            else if(colorTexto==="") {
                messageError(t("alert_text_boton"))
                return
            }
            else if (!isValidHex(color)) {
                messageError(t("alert_hexa"));
                return;
            }
            else if (!isValidHex(colorTexto)) {
                messageError(t("alert_color_hex"));
                return
            }

            if(arrEdit.posicion) {
                const editArr = visual.map(item => {
                    if(item.fila === arrEdit.fila) {
                        return {
                            ...item,
                            texto: texto,
                            url: url,
                            color: color,
                            colorTexto: colorTexto,
                            posicion: alinear
                        }
                    }
                    return item
                })
                setVisual(editArr)
            }
            else {
                setVisual([...visual, {
                    fila: visual.length + 1,
                    tipo: "boton",
                    texto: texto,
                    url: url,
                    color: color,
                    colorTexto: colorTexto,
                    posicion: alinear,
                }])
            }
            setView("1")
            setArrEdit({})
        }

        useEffect(() => {
            if(arrEdit.tipo === "boton") {
                setTexto(arrEdit.texto)
                setUrl(arrEdit.url)
                setColor(arrEdit.color)
                setColorTexto(arrEdit.colorTexto)
                setAlinear(arrEdit.posicion)
            }
        }, [])

        return (
            <Flex vertical>
                <Flex vertical>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("text_boton")}</Text>
                    <Input placeholder="Texto" size="large" onChange={(e) => setTexto(e.target.value)} value={texto} />
                </Flex>
                
                <Flex vertical style={{marginTop: "15px"}}>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("url_boton")}</Text>
                    <Input placeholder="http://" size="large" onChange={(e) => setUrl(e.target.value)} value={url} />
                </Flex>

                <Flex gap={10} style={{marginTop: "15px"}}>
                    <Flex vertical style={{width: "50%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("color_boton")}</Text>
                        <ColorPicker 
                            style={{width: "100%", display: "flex", justifyContent :"flex-start"}} 
                            size="large" 
                            onChange={(value) => setColor(value.toHexString())} 
                            value={color} 
                            showText
                        />
                    </Flex>
                    <Flex vertical style={{width: "50%"}}>
                        <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("color_text_boton")}</Text>
                        <ColorPicker 
                            style={{width: "100%", display: "flex", justifyContent :"flex-start"}} 
                            size="large" 
                            onChange={(value) => setColorTexto(value.toHexString())} 
                            value={colorTexto} 
                            showText
                        />
                    </Flex>
                </Flex>

                <Flex vertical style={{marginTop: "15px"}}>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("align_boton")}</Text>
                    <Select
                        value={alinear}
                        size="large"
                        style={{width: "100%"}}
                        onChange={(e) => setAlinear(e)}
                        options={[
                            {
                                value: 'left',
                                label: t("left"),
                            },
                            {
                                value: 'center',
                                label: t("center"),
                            },
                            {
                                value: 'right',
                                label: t("right"),
                            },
                        ]}
                    />
                </Flex>

                <Flex align="center" justify="center" style={{width: "100%", marginTop: "20px"}}>
                    <Button onClick={sendFragment} type="ghost" style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{arrEdit.posicion ? t("btn_modificate") : t("btn_add")}</Button>
                    <Button onClick={resetElement} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_onback")}</Button>
                </Flex>
            </Flex>
        )
    }



    function Link() {
        const [texto, setTexto] = useState('')
        const [url, setUrl] = useState('')
        const [alinear, setAlinear] = useState('left')

        const sendFragment = () => {
            if(texto==="") {
                messageError(t("alert_text"))
                return
            }
            else if(url==="") {
                messageError(t("alert_url"))
                return
            }

            if(arrEdit.posicion) {
                const editArr = visual.map(item => {
                    if(item.fila === arrEdit.fila) {
                        return {
                            ...item,
                            texto: texto,
                            url: url,
                            posicion: alinear
                        }
                    }
                    return item
                })
                setVisual(editArr)
            }
            else {
                setVisual([...visual, {
                    fila: visual.length + 1,
                    tipo: "link",
                    texto: texto,
                    url: url,
                    posicion: alinear,
                }])
            }
            setView("1")
            setArrEdit({})
        }

        useEffect(() => {
            if(arrEdit.tipo === "link") {
                setTexto(arrEdit.texto)
                setUrl(arrEdit.url)
                setAlinear(arrEdit.posicion)
            }
        }, [])

        return (
            <Flex vertical>
                <Flex vertical>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("text_link")}</Text>
                    <Input placeholder="Texto" size="large" onChange={(e) => setTexto(e.target.value)} value={texto} />
                </Flex>
                
                <Flex vertical style={{marginTop: "15px"}}>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("url_link")}</Text>
                    <Input placeholder="http://" size="large" onChange={(e) => setUrl(e.target.value)} value={url} />
                </Flex>

                <Flex vertical style={{marginTop: "15px"}}>
                    <Text style={{fontSize: "16px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("align_link")}</Text>
                    <Select
                        value={alinear}
                        size="large"
                        style={{width: "100%"}}
                        onChange={(e) => setAlinear(e)}
                        options={[
                            {
                                value: 'left',
                                label: t("left"),
                            },
                            {
                                value: 'center',
                                label: t("center"),
                            },
                            {
                                value: 'right',
                                label: t("right"),
                            },
                        ]}
                    />
                </Flex>

                <Flex align="center" justify="center" style={{width: "100%", marginTop: "20px"}}>
                    <Button onClick={sendFragment} type="ghost" style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{arrEdit.posicion ? t("btn_modificate") : t("btn_add")}</Button>
                    <Button onClick={resetElement} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_onback")}</Button>
                </Flex>
            </Flex>
        )
    }

    // Para agregar las clases al CSS
    useEffect(() => {
        if (visual.length > 0) {
            let newCssValue = cssValue.split('\n');
            let existingClasses = newCssValue.map(line => line.match(/^\.(\w+)/)).filter(Boolean).map(match => match[1]);
    
            // Crear un conjunto de nombres de clases en visual
            let visualClasses = new Set(visual.map(element => `${element.tipo}${element.fila}`));
    
            // Filtrar las clases que ya no están en visual, excepto .body
            let filteredCssValue = [];
            let skip = false;
            for (let line of newCssValue) {
                const match = line.match(/^\.(\w+)/);
                if (match) {
                    const className = match[1];
                    if (className === 'body' || visualClasses.has(className)) {
                        filteredCssValue.push(line);
                        skip = false;
                    } else {
                        skip = true;
                    }
                } else if (!skip) {
                    filteredCssValue.push(line);
                } else if (line.includes('}')) {
                    skip = false;
                }
            }
    
            // Agregar nuevas clases que no están en cssValue
            visual.forEach((element) => {
                const newClassName = `${element.tipo}${element.fila}`;
                if (!existingClasses.includes(newClassName)) {
                    filteredCssValue.push(`.${newClassName} {}`);
                }
            });
    
            // Eliminar líneas vacías
            filteredCssValue = filteredCssValue.filter(line => line.trim() !== '');
    
            setCssValue(filteredCssValue.join('\n') + '\n');
        }
    }, [visual]);

    function CSS() {

        const editorRef = useRef(null);
        const onSaveCss = () => {
            const currentCssValue = editorRef.current.editor.getValue();
            setCssValue(currentCssValue);
            setView("1");
        }

        return (
            <Flex vertical>
                <Text style={{fontSize: "16px", marginBottom: "10px", color: getCongif("colorPrimario"), fontWeight: "600"}}>{t("text_css")}</Text>
                <AceEditor
                    mode="css"
                    theme="monokai"
                    name="editor_css"
                    editorProps={{ $blockScrolling: true }}
                    width="100%"
                    height="320px"
                    fontSize={14}
                    highlightActiveLine={true}
                    value={cssValue}
                    ref={editorRef}
                    setOptions={{
                        useWorker: false,
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        behavioursEnabled: true,
                    }}
                />
                <Flex align="center" justify="center" style={{width: "100%", marginTop: "20px"}}>
                    <Button onClick={onSaveCss} type="ghost" style={{fontWeight: "600", color: getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`}}>{t("btn_save")}</Button>
                    <Button onClick={resetElement} type="ghost" style={{fontWeight: "600", color: "gray", border: "1.8px solid gray", marginLeft: "20px"}}>{t("btn_onback")}</Button>
                </Flex>
            </Flex>
        )
    }
    
    
    // Para saber en que vista estamos
    let vista = null
    if (view === "1") {
        vista = <Content />
    }
    else if (view === "2") {
        vista = <Imagen />
    }
    else if (view === "3") {
        vista = <Texto />
    }
    else if (view === "4") {
        vista = <Boton />
    }
    else if (view === "5") {
        vista = <Link />
    }
    else if (view === "6") {
        vista = <CSS />
    }

    return (
        <Flex vertical style={{width: "60%", padding: "0 20px"}}>
            <Flex gap={15}>
                <Flex vertical style={{width: "50%"}}>
                    <Text style={{fontSize: "16px", fontWeight: "600", color: getCongif("colorSecundario")}}>{t("name_template")}</Text>
                    <Input placeholder={t("name_template")} onChange={(e) => setName(e.target.value)} value={name} />
                </Flex>
                <Flex vertical style={{width: "50%"}}>
                    <Text style={{fontSize: "16px", fontWeight: "600", color: getCongif("colorSecundario")}}>{t("subject_mail")}</Text>
                    <Input placeholder={t("subject_mail")} onChange={(e) => setAsunto(e.target.value)} value={asunto} />
                </Flex>
            </Flex>

            <Title level={3} style={{color: getCongif("colorPrimario"), marginTop: "20px", textAlign: "center"}}>{t("add_element")}</Title>

            <Flex justify="space-around" style={{paddingBottom: "12px", borderBottom: `1.5px solid ${getCongif("colorPrimario")}`}}>
                <Button onClick={() => setView("2")} type="ghost" style={{fontWeight: "600", color: view==="2" ? "#fff" : getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`, backgroundColor: view==="2" && getCongif("colorPrimario"), textTransform: "uppercase"}}>{t("image")}</Button>
                <Button onClick={() => setView("3")} type="ghost" style={{fontWeight: "600", color: view==="3" ? "#fff" : getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`, backgroundColor: view==="3" && getCongif("colorPrimario"), textTransform: "uppercase"}}>{t("text")}</Button>
                <Button onClick={() => setView("4")} type="ghost" style={{fontWeight: "600", color: view==="4" ? "#fff" : getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`, backgroundColor: view==="4" && getCongif("colorPrimario"), textTransform: "uppercase"}}>{t("button")}</Button>
                <Button onClick={() => setView("5")} type="ghost" style={{fontWeight: "600", color: view==="5" ? "#fff" : getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`, backgroundColor: view==="5" && getCongif("colorPrimario"), textTransform: "uppercase"}}>{t("link")}</Button>
                {visual.length > 0 && <Button onClick={() => setView("6")} type="ghost" style={{fontWeight: "600", color: view==="6" ? "#fff" : getCongif("colorPrimario"), border: `1.8px solid ${getCongif("colorPrimario")}`, backgroundColor: view==="6" && getCongif("colorPrimario"), textTransform: "uppercase"}}>{t("css")}</Button>}
            </Flex>

            <Flex vertical className="skin__scrollbar" style={{width: "100%", height: "68vh", marginTop: "10px", overflow: "auto"}}>{vista}</Flex>
        
            {contextHolder}
        </Flex>
    )
}
